import { IMenuItem, ISubMenu } from '@sapientpro/sapientpro-data-models';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { theme } from '../../../store/theme';
import Img from '../../Img';

import styles from './Submenu.module.scss';

const SubmenuItem = ({ submenuItem }: { submenuItem: IMenuItem | ISubMenu }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={styles.submenu__sublist__item}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          {submenuItem?.type === 'submenu' && submenuItem.menuIcon && (
            <Img
              src={submenuItem.menuIcon[appTheme].url}
              alt={submenuItem.menuIcon[appTheme].alternativeText}
            />
          )}
        </div>
      </div>
      <div className={styles.submenu__sublist__item__text}>
        {submenuItem?.slug ? (
          <Link
            href={`/${submenuItem.slug}`}
            className={styles.title}
          >
            {submenuItem.title}
          </Link>
        ) : (
          <p className={styles.title}>{submenuItem.title}</p>
        )}
        {submenuItem?.type === 'submenu' && !!submenuItem?.content.length && (
          <p className={styles.technologiesList}>
            {submenuItem.content.map((submenuItemContentPoint, index, submenuItemContent) => (
              <Link
                key={`${submenuItemContentPoint.id}/${submenuItemContentPoint.title}`}
                href={`/subservice/${submenuItemContentPoint.slug}`}
                className={styles.technologyLink}
              >
                {submenuItemContentPoint.title}
                {index < submenuItemContent.length - 1 ? ', ' : ''}
              </Link>
            ))}
          </p>
        )}
      </div>
    </div>
  );
};

const Submenu = ({ data, className }: { data: ISubMenu | null; className: string }) => {
  const COLUMN_COUNT = 3;
  const groupList = (originalArray: ISubMenu, numberOfGroups: number) => {
    const groupedArray = [];
    const groupSize = Math.ceil(originalArray.content.length / numberOfGroups);
    for (let i = 0; i < originalArray.content.length; i += groupSize) {
      groupedArray.push(originalArray.content.slice(i, i + groupSize));
    }
    return groupedArray;
  };

  return (
    <section
      className={classNames(styles.submenu, className, {
        [styles.isVisible]: !!data?.content?.length,
      })}
    >
      <ul className={styles.submenu__mainlist}>
        {data
          && groupList(data, COLUMN_COUNT).map((column) => (
            <li
              key={`${column[0].id}/${column[0].title}`}
              className={styles.submenu__mainlist__item}
            >
              <ul className={styles.submenu__sublist}>
                {column.map((navItem) => (
                  <li key={`${navItem.id}/${navItem.title}`}>
                    {navItem.type === 'submenu' ? (
                      <SubmenuItem submenuItem={navItem} />
                    ) : navItem.slug ? (
                      <Link
                        href={`/${navItem.slug}`}
                        className={styles.submenu__sublist__item}
                      >
                        <span className={styles.title}>{navItem.title}</span>
                      </Link>
                    ) : (
                      <span className={styles.submenu__sublist__item}>{navItem.title}</span>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default Submenu;
