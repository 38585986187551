'use client';

import { useAtom } from 'jotai';
import React, { useState } from 'react';
import contactModalTriggerButton from '../store/contactForm';
import styles from './ContactModal.module.scss';
import Form from './Form/Form';
import { FormComponentData } from './Form/interfaces';

const ContactModal = () => {
  const [contactModalTrigger, setContactsModalTrigger] = useAtom(contactModalTriggerButton);

  const [nameValue, setNameValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [serviceValue, setServiceValue] = useState<string>('');
  const [messageValue, setMessageValue] = useState<string>('');

  const clearForm = () => {
    setNameValue('');
    setEmailValue('');
    setServiceValue('');
    setMessageValue('');
  };

  const formData: FormComponentData = {
    title: 'UNLEASH YOUR **IDEA**',
    description: 'Feel free to contact us and we will get back to you as soon as we can',
    fields: [
      {
        id: 'nameField',
        name: 'name',
        type: 'input',
        inputType: 'text',
        label: 'Enter your name',
        value: nameValue,
        setValue: setNameValue,
        errors: {
          required: 'Please enter your name',
        },
      },
      {
        id: 'emailField',
        name: 'email',
        type: 'input',
        inputType: 'email',
        label: 'Enter your email',
        value: emailValue,
        setValue: setEmailValue,
        errors: {
          required: 'Please provide your email address',
          custom: [{
            condition: emailValue !== '' && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue),
            value: 'Please enter a valid email',
          }],
        },
      },
      {
        name: 'service',
        type: 'select',
        label: 'Choose service you interested in from a list',
        id: 'serviceSelect',
        value: serviceValue,
        setValue: setServiceValue,
        list: [
          'Web Development',
          'Marketing Software Development',
          'Web3 technologies',
          'Bot Development',
          'Fintech Industry',
        ],
      },
      {
        id: 'projectDescriptionField',
        name: 'projectDescription',
        type: 'textarea',
        label: 'Tell us about your project',
        value: messageValue,
        setValue: setMessageValue,
        errors: {
          required: 'Please describe your project',
        },
      },
    ],
    submitData: {
      endpoint: 'contact-form-lead',
      value: {
        name: nameValue,
        email: emailValue,
        service: serviceValue,
        message: messageValue,
        clickedButton: contactModalTrigger || undefined,
      },
    },
    openedModal: contactModalTriggerButton,
  };

  // disable eslint rule to prevent returning not JSX component
  return ( // eslint-disable-next-line
    <>
      {contactModalTrigger && (
        <div className={styles.contactModal}>
          <button
            type='button'
            className={styles.closeButton}
            onClick={() => setContactsModalTrigger(null)}
          >
            <svg>
              <use
                xlinkHref='/media/menuClose.svg#menuCloseSVG'
                href='/media/menuClose.svg#menuCloseSVG'
              />
            </svg>
          </button>
          <Form
            data={formData}
            onSuccessSubmit={clearForm}
          />
        </div>
      )}
    </>
  );
};

export default ContactModal;
