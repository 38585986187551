'use client';

import { IMenuResponse, IPopularSearch } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import client from '../../apiClient';
import contactModalTriggerButton from '../../store/contactForm';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import isEducationModalOpen from '../../store/educationForm';
import isSearchOpen from '../../store/search';
import isVacanciesModalOpen from '../../store/vacanciesForm';
import Logo from '../Logo';
import NavMenu from '../NavMenu/NavMenu';
import Search from '../Search/Search';
import styles from './Header.module.scss';
import Menu from './Menu';
import ThemeToggle from './ThemeToggle';

const getQuickSearchData = async () => {
  try {
    return await client.get<IPopularSearch[]>('popular-searches');
  } catch (e) {
    console.error(e.message);
    throw new Error(e);
  }
};

interface HeaderProps {
  menuData: IMenuResponse,
  isAbsolute?: boolean,
}

const Header = ({ menuData, isAbsolute = false }: HeaderProps) => {
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const logoWidth = deviceSize === ScreenSize.DESKTOP_HUGE ? 131 : 100;
  const logoHeight = deviceSize === ScreenSize.DESKTOP_HUGE ? 48 : 32;
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const pathname = usePathname();
  const [showSearch, setShowSearch] = useAtom(isSearchOpen);
  const [quickSearches, setQuickSearches] = useState<null | IPopularSearch[]>(null);
  const isContactModalShowed = useAtomValue(contactModalTriggerButton);
  const isVacanciesModalShowed = useAtomValue(isVacanciesModalOpen);
  const isEducationModalShowed = useAtomValue(isEducationModalOpen);

  const getQuickSearchResults = async () => {
    try {
      const data = await getQuickSearchData();
      setQuickSearches(data);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    getQuickSearchResults();
  }, []);

  useEffect(() => {
    setShowSearch(false);
    setIsMenuOpened(false);
  }, [pathname]);

  useEffect(() => {
    document.documentElement.style.overflowY = (
      isMenuOpened
      || showSearch
      || isContactModalShowed
      || isVacanciesModalShowed
      || isEducationModalShowed
    ) ? 'hidden' : 'unset';
  }, [showSearch, isMenuOpened, isContactModalShowed, isVacanciesModalShowed, isEducationModalShowed]);

  const setRealViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  useEffect(() => {
    setRealViewportHeight();
    window.addEventListener('resize', setRealViewportHeight);

    return () => {
      window.removeEventListener('resize', setRealViewportHeight);
    };
  }, []);

  return (
    <>
      <header className={classnames(styles.header, {
        [styles.header_absolute]: isAbsolute,
      })}
      >
        <Link
          passHref
          href='/'
          aria-label='Get back to main page'
          className={styles.logo}
        >
          <Logo
            width={logoWidth}
            height={logoHeight}
          />
        </Link>
        <div className={styles.header__rightBlock}>
          <Menu data={menuData} />
          <button
            type='button'
            name='Search'
            aria-label='search'
            className={styles.searchButton}
            onClick={() => setShowSearch(true)}
          >
            <svg>
              <use
                xlinkHref='/media/search.svg#searchSVG'
                href='/media/search.svg#searchSVG'
              />
            </svg>
          </button>
          <ThemeToggle />
        </div>
        <button
          aria-label='Toggle menu'
          type='button'
          onClick={() => setIsMenuOpened(!isMenuOpened)}
          className={styles.menuButton}
        >
          <div className={styles.menuButton__line} />
          <div className={styles.menuButton__line} />
        </button>
        {isMenuOpened && menuData && (
          <NavMenu
            data={menuData}
            onClose={() => setIsMenuOpened(false)}
          />
        )}
      </header>
      {showSearch && <Search quickSearches={quickSearches} />}
    </>
  );
};

export default Header;
